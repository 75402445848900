const remainingForms = document.querySelectorAll('.dates-remaining-stock');

remainingForms.forEach((remainingForm) => {
    const remainingInput = remainingForm.querySelector(
        'input[name=remainingStock]',
    );
    remainingInput.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });
    remainingInput.addEventListener('change', (event) => {
        event.preventDefault();
        fetch(remainingForm.getAttribute('action'), {
            method: remainingForm.getAttribute('method'),
            body: new FormData(remainingForm),
        })
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then(function (data) {
                if (data.remainingStock) {
                    remainingInput?.classList.add('input--invalid');
                    return;
                }
                remainingInput?.classList.add('input--valid');
            })
            .catch(async function (error) {
                console.warn(error);
            });
    });

    remainingInput.addEventListener('change', (event) => {
        remainingInput?.classList.remove('input--valid');
    });
});
