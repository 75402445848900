const triggerClickButtons = document.querySelectorAll(
    "[data-trigger-click='add-image-select']",
);

triggerClickButtons?.forEach((btn) => {
    btn.addEventListener('click', function (event) {
        event.preventDefault();
        let target = event.currentTarget.dataset.triggerClick;
        document.querySelector(`[data-trigger-id=${target}]`).click();

        return false;
    });
});

document
    .querySelector("[data-trigger-submit='add-image-submit']")
    ?.addEventListener('change', function (event) {
        document
            .getElementById(event.currentTarget.dataset.triggerSubmit)
            .click();
    });
