const selections = document.querySelectorAll('[data-selection]');

selections.forEach((selection) => {
    const selectionSelector =
        selection.attributes.getNamedItem('data-selection').value;
    const selectionItems = document.querySelectorAll(
        `[data-selection-item=${selectionSelector}]`,
    );
    const selectionnedClass = selection.attributes.getNamedItem(
        'data-selection-class',
    ).value;
    const multipleSelections = !!selection.attributes.getNamedItem(
        'data-selection-multiple',
    );
    const disabledButton = document.querySelector('[data-disabled-button]');

    selectionItems.forEach((item) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();

            if (!multipleSelections) {
                selection.clear();
            }

            if (disabledButton) {
                disabledButton.disabled = false;
            }

            if (selection.isActive()) {
                if (item.classList.contains(selectionnedClass)) {
                    item.classList.remove(selectionnedClass);
                } else {
                    item.classList.add(selectionnedClass);
                }
            }
        });
    });

    selection.getSelectionnedItemIds = function () {
        let ids = [];
        const results = Array.from(
            this.getElementsByClassName(selectionnedClass),
        );

        results.forEach((element) =>
            ids.push(element.attributes.getNamedItem('data-id').value),
        );

        return ids;
    };

    selection.countSelectionnedItems = () =>
        document.getElementsByClassName(selectionnedClass).length;

    selection.isActive = () =>
        selection.attributes.getNamedItem('data-selection-active').value ===
        'enabled';

    selection.clear = () => {
        let items = Array.from(
            document.getElementsByClassName(selectionnedClass),
        );
        items.forEach((item) => {
            item.classList.remove(selectionnedClass);
        });
    };
});

const selectButtons = document.querySelectorAll('[data-toggle=select]');

selectButtons.forEach((selectButton) => {
    selectButton.addEventListener('click', (event) => {
        let targetElements = document.querySelectorAll(
            selectButton.attributes.getNamedItem('data-target').value,
        );
        targetElements.forEach((targetElement) => {
            const active = targetElement.attributes.getNamedItem(
                'data-selection-active',
            );
            const actionButtons = document.querySelectorAll(
                `[data-action-target=\\#${targetElement.id}]`,
            );
            if (active.value === 'enabled') {
                active.value = 'disabled';
                targetElement.clear();
                actionButtons.forEach((actionButton) => {
                    actionButton.setAttribute('disabled', 'disabled');
                });
            } else {
                active.value = 'enabled';
                actionButtons.forEach((actionButton) => {
                    actionButton.removeAttribute('disabled');
                });
            }
        });
    });
});
