const adminNs = {
    initDraggableEntityRows: function () {
        const rows = document.querySelectorAll('[data-sortable-row]');
        let dragSrcEl = null;
        let startPosition = null;
        let endPosition = null;
        let parent;
        let entityId;

        function handleDragStart(e) {
            dragSrcEl = this;
            entityId = dragSrcEl.attributes.getNamedItem('rel')?.value;
            dragSrcEl.style.opacity = '0.4';
            parent = dragSrcEl.parentNode;
            startPosition =
                Array.prototype.indexOf.call(parent.children, dragSrcEl) + 1;

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', this.innerHTML);
        }

        function handleDragOver(e) {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';
            return false;
        }

        function handleDragEnter(e) {
            this.classList.add('over');
        }

        function handleDragLeave(e) {
            this.classList.remove('over');
        }

        async function handleDrop(e) {
            if (e.stopPropagation) {
                e.stopPropagation();
            }

            if (dragSrcEl !== this) {
                let endpoint = dragSrcEl.attributes
                    .getNamedItem('data-controller-url')
                    ?.value?.replace('id', entityId);
                const srcEntityId = this.attributes.getNamedItem('rel')?.value;
                const destEntityId =
                    dragSrcEl.attributes.getNamedItem('rel')?.value;

                const srcTitlePosition =
                    dragSrcEl.querySelector('[data-position]').innerHTML;
                const destTitlePosition =
                    this.querySelector('[data-position]').innerHTML;

                endPosition =
                    Array.prototype.indexOf.call(parent.children, this) + 1;

                const regex = new RegExp(
                    `\\d{${startPosition.toString().length}}$`,
                );
                endpoint = endpoint.replace(regex, endPosition);

                dragSrcEl.innerHTML = this.innerHTML;
                this.innerHTML = e.dataTransfer.getData('text/html');
                dragSrcEl.querySelector('[data-position]').innerHTML =
                    srcTitlePosition;
                this.querySelector('[data-position]').innerHTML =
                    destTitlePosition;
                this.setAttribute('rel', destEntityId);

                dragSrcEl.setAttribute('rel', srcEntityId);
                const ajaxRequest = new XMLHttpRequest();
                ajaxRequest.open('POST', `${endpoint}`, false);
                ajaxRequest.onerror = function (e) {
                    console.warn(e);
                    console.warn(
                        'Une erreur est survenue veuillez recharger la page',
                    );
                };
                await ajaxRequest.send();
                window.location.reload();
            }
            return false;
        }

        function handleDragEnd(e) {
            this.style.opacity = '1';
            [].forEach.call(rows, function (row) {
                row.classList.remove('over');
            });
        }

        [].forEach.call(rows, function (row) {
            row.addEventListener('dragstart', handleDragStart, false);
            row.addEventListener('dragenter', handleDragEnter, false);
            row.addEventListener('dragover', handleDragOver, false);
            row.addEventListener('dragleave', handleDragLeave, false);
            row.addEventListener('drop', handleDrop, false);
            row.addEventListener('dragend', handleDragEnd, false);
        });
    },

    /*/!**
     * Primary Admin initialization method.
     * @returns {boolean}
     *!/*/
    init: function () {
        this.initDraggableEntityRows();
        return true;
    },
};

adminNs.init();
