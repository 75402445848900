const selections = document.querySelectorAll('[data-pictures-btn]');
selections.forEach((pictureBtn) => selectButtonListenner(pictureBtn));

function selectButtonListenner(btn) {
    btn.addEventListener('click', (e) => {
        const galleryId = btn.attributes.getNamedItem('data-gallery')?.value;
        const gallery = document.getElementById(galleryId);

        const formId = btn.attributes.getNamedItem('data-picture-form')?.value;
        const form = document.getElementById(formId);
        const selectionnedItems = gallery.getSelectionnedItemIds();
        const selectId = btn.attributes.getNamedItem(
            'data-picture-select',
        )?.value;
        const select = form.querySelector(selectId);

        const options = Array.from(select.getElementsByTagName('option'));
        options.forEach((e) => {
            if (selectionnedItems.includes(e.value)) {
                e.selected = true;
            }
        });
    });
}
