import diff_match_patch from 'diff-match-patch';

const diff = document.querySelectorAll('.diff__version__property');

diff.forEach((property) => {
    compareTexts(property, property.dataset.old, property.dataset.new);
});

function compareTexts(element, text1, text2) {
    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(text1, text2);
    dmp.diff_cleanupSemantic(diffs);

    element.innerHTML = '';

    diffs.forEach(function (part) {
        const span = document.createElement('span');
        const color = part[0];
        const text = part[1];

        if (color === 1) {
            // Ajouté
            span.className = 'diff__added';
        } else if (color === -1) {
            // Supprimé
            span.className = 'diff__removed';
        }

        span.textContent = text;
        element.appendChild(span);
    });
}
