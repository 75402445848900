const selectFilters = document.querySelectorAll('.filter-select');
const filterBtn = document.querySelector('.select-button');

const selectedFilters = [];

selectFilters.forEach((f) => {
    f.addEventListener('change', () => {
        if (f.value) {
            filterBtn.classList.add('active-filter');
            selectedFilters.push(f.value);
            return;
        }
        selectedFilters.pop();
        if (selectedFilters.length === 0) {
            filterBtn.classList.remove('active-filter');
        }
    });
});
