import { MODAL_EVENT_CONTENT_READY } from './library/modal';

const dataRadioFields = document.querySelectorAll('[data-radio-field]');
const dataSelectFields = document.querySelectorAll('[data-select-field]');

dataRadioFields.forEach((submitField) => {
    submitField.addEventListener('input', (event) => {
        let n = event.target.name;
        if (event.target.value == 1) {
            document.getElementById(n).classList.remove('hidden');
        }
        if (event.target.value == 0) {
            document.getElementById(n).classList.add('hidden');
        }
    });
});

dataSelectFields.forEach((field) => {
    field.addEventListener('change', (event) => {
        let name = event.target.name;
        let value = event.target.value;

        const elements = document.getElementsByClassName(name);
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id == value) {
                elements[i].classList.remove('hidden');
            } else {
                elements[i].classList.add('hidden');
            }
        }
    });
    field.dispatchEvent(new Event('change'));
});
