export const MODAL_EVENT_OPEN = 'open';
export const MODAL_EVENT_CONTENT_READY = 'content-ready'; // used for xhr content (specifics custom scripts)
export const MODAL_EVENT_CLOSE = 'close';
import tippy from 'tippy.js';

window.onload = () => {
    const buttons = document.querySelectorAll('[data-toggle=modal]');

    for (let button of buttons) {
        let target = button.attributes.getNamedItem('data-target').value;
        const modal = document.querySelector(target);

        button.addEventListener('click', function (e) {
            e.preventDefault();

            let openEvent = new CustomEvent(MODAL_EVENT_OPEN, {
                detail: {
                    controller:
                        button.attributes.getNamedItem('data-controller')
                            ?.value,
                },
            });
            modal.dispatchEvent(openEvent);

            const modalClose = modal.querySelectorAll('[data-dismiss=dialog]');

            for (let close of modalClose) {
                close.addEventListener('click', () => {
                    modal.classList.remove('modal--show');
                    const closeEvent = new Event(MODAL_EVENT_CLOSE);
                    modal.dispatchEvent(closeEvent);
                });
            }
        });
    }

    const modals = document.querySelectorAll('[data-modal]');

    modals.forEach((modal) => {
        modal.addEventListener(MODAL_EVENT_OPEN, async (e) => {
            modal.classList.add('modal--show');

            const controller = e.detail?.controller;

            if (controller === undefined) {
                return false;
            }
            const response = await fetch(controller);
            const content = await response.text();

            modal.replaceContent(content);

            const tooltips = document.querySelectorAll('.tooltip');

            // Loads tooltips in modal
            for (let tooltip of tooltips) {
                tippy(tooltip, {
                    content: tooltip.dataset.tooltip,
                    allowHTML: true,
                    placement: 'right',
                    animation: 'shift-away',
                    interactive: true, // allows for copy pasting the tooltip
                    theme: 'dkt',
                });
            }
        });

        modal.addEventListener(MODAL_EVENT_CLOSE, (e) => {
            const contentDiv = modal.querySelector('[data-content=true]');
            if (contentDiv?.hasChildNodes()) {
                contentDiv.removeChild(contentDiv.firstChild);
            }

            modal.classList.remove('modal--show');
        });

        modal.addEventListener('click', function () {
            const closeEvent = new Event(MODAL_EVENT_CLOSE);
            modal.dispatchEvent(closeEvent);
        });

        modal?.children[0]?.addEventListener('click', function (e) {
            e.stopPropagation();
        });

        modal.replaceContent = (content) => {
            const contentDiv = modal.querySelector('[data-content=true]');

            if (contentDiv?.hasChildNodes()) {
                contentDiv.removeChild(contentDiv.firstChild);
            }

            let newElement = document.createElement('div');
            newElement.innerHTML = content;

            let scripts = newElement.querySelectorAll('script');

            scripts.forEach((script) => {
                script.remove();
            });

            contentDiv?.insertAdjacentElement('afterbegin', newElement);

            const contentReadyEvent = new Event(MODAL_EVENT_CONTENT_READY);
            modal.dispatchEvent(contentReadyEvent);
        };

        modal?.addEventListener(MODAL_EVENT_CONTENT_READY, (contentEvent) => {
            var dataSubmitFields = modal.querySelectorAll(
                '[data-submit-field]',
            );
            var modalForm = modal.querySelector('[data-modal-form]');
            var submitButton = modal.querySelector('button[type=submit]');

            submitButton.setAttribute('form', modalForm.id);

            dataSubmitFields.forEach((submitField) => {
                submitField.addEventListener('input', (event) => {
                    let submitEvent = new CustomEvent('submit', {
                        detail: { dataSubmitField: true },
                    });
                    modalForm.dispatchEvent(submitEvent);
                });
            });
        });
    });
};
