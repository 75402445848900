const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const DropTarget = require('@uppy/drop-target');
const XHRUpload = require('@uppy/xhr-upload');
const French = require('@uppy/locales/lib/fr_FR');

const uppyAreas = document.querySelectorAll('[data-uppy-area]');

uppyAreas.forEach((area) => {
    const endpoint = area.attributes.getNamedItem('data-url-upload')?.value;
    const selectionAreaId = area.attributes.getNamedItem(
        'data-selection-target',
    )?.value;
    const selectAreaElement = document.getElementById(selectionAreaId);
    const selectAreaMultiple = !!selectAreaElement?.attributes.getNamedItem(
        'data-selection-multiple',
    );

    const formSelector = area.attributes.getNamedItem(
        'data-selection-form',
    )?.value;
    const selectionFormElement = document.getElementById(formSelector);
    const selectionSelectElement =
        selectionFormElement?.getElementsByTagName('select')[0];

    if (endpoint) {
        const uppy = new Uppy({
            id: `${area.id}`,
            autoProceed: false,
            restrictions: {
                maxFileSize: 10000000,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/jpeg', 'image/png'],
                requiredMetaFields: [],
            },
            locale: French,
        })
            .use(Dashboard, {
                id: `${area.id}`,
                trigger: `.${area.id}`,
                inline: true,
                target: `#${area.id}`,
                showProgressDetails: true,
                height: 200,
                metaFields: [
                    { id: 'name', name: 'Name', placeholder: 'file name' },
                ],
                browserBackButtonClose: false,
            })
            .use(DropTarget, { target: document.body })
            .use(XHRUpload, {
                target: `#${area.id}`,
                endpoint,
                formData: true,
                fieldName: 'upload_file[uploadedFile]',
            });

        uppy.on('complete', (result) => {
            const picturesAdded = result?.successful;

            const reloadOnComplete = area?.attributes.getNamedItem(
                'data-uppy-reload-on-complete',
            );
            const template =
                selectAreaElement?.attributes.getNamedItem(
                    'data-prototype',
                )?.value;

            if (template) {
                const optionTemplate =
                    '<option value="_pictureId_" selected>_pictureId_</option>';

                if (!selectAreaMultiple) {
                    selectAreaElement?.clear();
                }

                picturesAdded?.forEach((picture) => {
                    var pictureId = picture.response.body.id;
                    var picturePath = picture.response.body.path;
                    var pictureHtml = template.replaceAll(
                        '_pictureId_',
                        pictureId,
                    );

                    pictureHtml = pictureHtml.replaceAll(
                        '_picturePath_',
                        picturePath,
                    );
                    selectAreaElement?.insertAdjacentHTML(
                        'beforeend',
                        pictureHtml,
                    );
                    selectionSelectElement?.insertAdjacentHTML(
                        'beforeend',
                        optionTemplate.replaceAll('_pictureId_', pictureId),
                    );
                });
            }

            if (reloadOnComplete) {
                location.reload();
            }
        });

        uppy.on('upload-error', (file, error, response) => {
            console.error('error with file:', file.id);
            console.error('error message:', error);
        });
    }
});
