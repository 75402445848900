import '@tarekraafat/autocomplete.js';
const autoComplete = require('@tarekraafat/autocomplete.js');

const autocompletes = document.querySelectorAll('[data-autocomplete-url]');

function selectOptionByValue(autoCompleteJS, value) {
    const select = document.querySelector(
        autoCompleteJS.input.attributes.getNamedItem('data-autocomplete-target')
            .value,
    );
    const options = Array.from(select.getElementsByTagName('option'));
    options.forEach((e) => {
        if (e.value === value.id) {
            e.selected = true;
        } else if (e.selected === true) {
            e.selected = false;
        }
    });
}

function resetSelect(autoCompleteJS) {
    const select = document.querySelector(
        autoCompleteJS.input.attributes.getNamedItem('data-autocomplete-target')
            .value,
    );
    select.selectedIndex = 0;
}

autocompletes?.forEach((autocomplete) => {
    const autoCompleteJS = new autoComplete({
        selector: `#${autocomplete.id}`,
        data: {
            src: async (query) => {
                try {
                    const sourceUrl =
                        autoCompleteJS.input.attributes.getNamedItem(
                            'data-autocomplete-url',
                        ).value;
                    const source = await fetch(`${sourceUrl}?term=${query}`);
                    return await source.json();
                } catch (error) {
                    return error;
                }
            },
            keys: ['displayedName'],
        },
        resultItem: {
            highlight: true,
        },
        wrapper: false,
        debounce: 300,
        events: {
            input: {
                results: (event) => {
                    if (event.detail.results.length === 1) {
                        const value = event.detail.results[0].value;
                        selectOptionByValue(autoCompleteJS, value);
                    }

                    if (event.detail.results.length === 0) {
                        resetSelect(autoCompleteJS);
                    }
                },
                selection: (event) => {
                    const value = event.detail.selection.value;
                    autoCompleteJS.input.value = value;
                    autoCompleteJS.input.value = value.displayedName;
                    selectOptionByValue(autoCompleteJS, value);
                },
            },
        },
    });
});
