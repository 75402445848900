import * as Turbo from '@hotwired/turbo';

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';

Turbo.session.drive = false;

// start the Stimulus application
import './bootstrap';

// import custom js
import './js/partner.js';
// needed for vtmn-play integration
import './css/global.css';
