const addFormToCollection = (e) => {
    const collectionHolder = document.querySelector(
        '.' + e.currentTarget.dataset.collectionHolderClass,
    );

    if (collectionHolder.classList.contains('hidden')) {
        collectionHolder.classList.remove('hidden');
        return;
    }

    const item = document.createElement('li');

    item.innerHTML = collectionHolder.dataset.prototype.replace(
        /__name__/g,
        collectionHolder.dataset.index,
    );

    collectionHolder.appendChild(item);

    collectionHolder.dataset.index++;

    // Removes add button if max collection is reached
    if (
        collectionHolder.dataset.index + 1 >=
        collectionHolder.dataset.maxCollection
    ) {
        e.target.classList.add('hidden');
    }

    removeParent();
};

function removeParent() {
    document.querySelectorAll('.remove-activity__button').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.target.parentElement.remove();
        });
    });
}

removeParent();

document.querySelectorAll('.add_item_link').forEach((btn) => {
    btn.addEventListener('click', addFormToCollection);
});
