const fileInput = document.querySelector('.import-file__input');
const fileDisplay = document.querySelector('.import-file__filename');

if (fileDisplay) {
    fileDisplay.textContent = '';
    if (fileInput) {
        fileInput.addEventListener('change', (e) => {
            const [file] = e.target.files;
            fileDisplay.textContent = file.name;
        });
    }
}
